import FullPageScroll from "./Components/FullPageScroll";

function App() {
  return (
    <div className="App">
      <FullPageScroll />
    </div>
  );
}

export default App;
